import { render, staticRenderFns } from "./Poco-das-Antas.vue?vue&type=template&id=83d8d028&scoped=true&"
import script from "./Poco-das-Antas.vue?vue&type=script&lang=js&"
export * from "./Poco-das-Antas.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83d8d028",
  null
  
)

export default component.exports